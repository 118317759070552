import { useRouter } from 'next/router';
import { rootPath } from '../module';
import { useCurrentEvent } from './useCurrentEvent';

// eslint-disable-next-line import/prefer-default-export
export function useEventRouter() {
  const router = useRouter();
  const [event] = useCurrentEvent();

  const currentDayIndex = router.query.dayindex ?? 0;

  return {
    getDashboardUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}`,
    getTasksUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/tasks`,
    getScheduleUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/schedule`,
    getFormUrl: (path, dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/${path}`,
    getDetailsUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/details`,
    getEquipmentUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/equipment`,
    getCateringUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/catering`,
    getCateringBeveragesUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/catering-beverages`,
    getAdditionalServicesUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/additional-services`,
    getCateringFoodUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/catering-food`,
    getSeatingUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/seating`,
    getChatUrl: (dayIndex = null) => `${rootPath}/${event?.id}/${dayIndex ?? currentDayIndex}/chat`,
    // Utilities
    changeEventDay: (index) => router.pathname
      .replace('[eventid]', event.id)
      .replace('[dayindex]', index),
  };
}
